import { Health } from './health';
import { VPN } from './vpn';

export interface Customer {
  id: string;
  first_name: string;
  email: string;
  referral_code: string;
  vpn_tunnels_limit: number;
}

/* eslint-disable-next-line */
export enum SubscriptionStatus {
  Active,
  Away,
  Paused,
  Cancelled,
  PaymentFailed,
  Pending
}
export const getSubscriptionStatusString = (status: SubscriptionStatus): string => SubscriptionStatus[status];

export interface Subscription {
  id: string;
  created: number;
  updated_at: number;
  current_period_end: number;
  plan: Plan;
  loc_id: string;
  payment_method: PaymentMethod;
  nbn_details: NbnDetails;
  ip: string;
  ip6: string;
  ip6_delegated: string
  poi: string;
  infrastructure_id: string;
  infrastructure_port: string;
  infrastructure_product_id: string;
  technology_type: string;
  traffic_class: string;
  dns_neptune_groups: number[];
  dns_allowed_regex_domains: string[];
  dns_allowed_exact_domains: string[];

  dns_blocked_regex_domains: string[];
  dns_blocked_exact_domains: string[];

  health: Health;

  status: SubscriptionStatus;
}

export interface NbnAddressDetail {
  id: string;
  latitude: number;
  longitude: number;
  techType: string;
  formattedAddress: string;
}

export interface NbnDetails {
  addressDetail: NbnAddressDetail;
}

export interface PaymentMethod {
  id: string;
  card_last_digits: string;
  card_type: string;
}

export interface Plan {
  id: string;
  aggregator_plan_id: string;
  name: string;
  cost: number;
  stripe_payment_link: string;
  stripe_product_id: string;
  up_mbps: number;
  down_mbps: number;
  description: string;
}

export interface CustomerObject {
  customer: Customer;
  subscriptions: { [key: string]: Subscription };
  vpns: { [key: string]: VPN}
}
