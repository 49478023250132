import * as ipaddr from 'ipaddr.js';
import CIDR from 'ip-cidr';
import dayjs from 'dayjs';

export const neptuneRanges = ['103.252.194.0/23', '2401:2520::/32'];

export const isNeptuneIp = (clientIp: string): boolean => {
  // Check if it's a valid IP address (IPv4 or IPv6)
  if (!ipaddr.isValid(clientIp)) {
    return false;
  }

  let isNeptune = false;
  neptuneRanges.forEach((ipRange) => {
    const cidr = new CIDR(ipRange);
    // Check if the client IP is within either of the Neptune ranges
    if (cidr.contains(clientIp)) {
      isNeptune = true;
    }
  });
  return isNeptune;
};

export function isBeforeNextDayMidnight(unixTimestamp: number) {
  // Convert the timestamp to a dayjs object in the browser's local time
  const timestamp = dayjs.unix(unixTimestamp);
  const today = dayjs().startOf('day'); // Start of the current day in local time
  const nextDayMidnight = today.add(1, 'day').endOf('day'); // End of the current day in local time

  // Check if the timestamp is within the same day and before next day's midnight
  return timestamp.isSame(today, 'day') && timestamp.isBefore(nextDayMidnight);
}
