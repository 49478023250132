import {
  Box,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  SlideFade,
  HStack,
  Text,
  Icon,
  Card,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMediaQuery,
  Tooltip,
  createStandaloneToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react';
import React, { useState, useCallback } from 'react';
import {
  FiActivity,
  FiCheckCircle,
  FiGlobe,
  FiHelpCircle,
  FiPause,
  FiPauseCircle,
  FiPlayCircle,
  FiShield,
  FiTrash,
} from 'react-icons/fi';

import {
  HamburgerIcon, WarningTwoIcon,
} from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import ringsClayUrl from '../../images/rings-clay.png';
import diamondClayUrl from '../../images/diamond-clay.png';
import { getSubscriptionStatusString, Subscription, SubscriptionStatus } from '../../models/customer';
import { Help } from '../Help';
import { getConfig, IBackendConfig } from '../../config';
import { SubscriptionAwayModal } from '../AwayMode';
import { SubscriptionPauseModal } from '../PauseMode';
import { SubscriptionCancelModal } from '../CancelsModal';
import { useCancelSubscriptionMutation, usePauseSubscriptionMutation, useUdpateSubscriptionMutation } from '../../services/eventsService';
import { isBeforeNextDayMidnight } from '../../utils';

const cfg: IBackendConfig = getConfig();
dayjs().locale('en-au');

const toast = createStandaloneToast();

const showToaster = (toasterTitle: string, status: 'success' | 'error' | 'info' | 'warning') => {
  toast.toast({
    title: toasterTitle,
    status,
    duration: 5000,
    position: 'top',
  });
};

export interface ServiceCardProps {
  sub: Subscription;
  serviceId: string;
  title: string;
  subTitle: string;
  monthlyCost: number;
  currentDiscount: number;
  downSpeed: number;
  upSpeed: number;
  onPick: () => void;
  focused: string;
  email: string;
  setFocused: (_: string) => void;
}

const IPv6Help = 'Neptune Internet allocates 64 bit IPv6 prefixes per service. An IPv6 prefix is like the first part of an IP address that helps identify a network. Think of it like a postal code or neighborhood name in a street address—it tells you the general area (network) where a device is located. In IPv6, addresses are much longer, and the prefix usually indicates the network or subnet where the device belongs. For example, if the address is 2001:0db8:85a3::8a2e:0370:7334, the prefix might be 2001:0db8::/32. This tells the network which part of the address is used for identifying the network and which part is for identifying the specific device.';

const IPv4Help = "An IP address is a unique identifier that allows devices to communicate on a network, like an address for sending data. IPv4 is the older system, using a 32-bit format that can handle about 4.3 billion addresses, but it's running out due to the growing number of internet-connected devices. IPv6 is the newer version, offering a much larger address space with 128-bit addresses, designed to support the internet's future growth and provide better security. Both are used today, but IPv6 is slowly replacing IPv4.";

const AtmosphereHelp = 'Atmosphere is a Sinkhole DNS Protection Service that is fully managed by Neptune Internet for you. Sinkhole DNS protection is like setting up a trap to catch harmful internet traffic before it reaches your device. Normally, when you type a website address, your device uses DNS (Domain Name System) to find the website\'s location. But sometimes, cybercriminals use fake websites (like phishing or malware sites) to steal information or infect your device.With sinkhole DNS protection, if your device tries to access a dangerous or fake website, the DNS server is set up to "sinkhole" the request. Instead of sending you to the bad site, it redirects you to a safe place, like a warning page or just blocks the connection. It’s like having a safety net that stops you from accidentally visiting harmful sites.';

const HealthCheckHelp = 'A health check will perform a diagnostic procedure that runs multiple tests on the NBN infrastructure and Neptune Internet equipment. We will also try to detect the status of your CPE (Customer Premises Equipment) and your current IP address.';

export const ServiceCard = ({
  serviceId,
  sub,
  title,
  subTitle,
  monthlyCost,
  downSpeed,
  upSpeed,
  onPick,
  email,
  focused,
  setFocused,
  currentDiscount,
}: ServiceCardProps) => {
  const [showIPHelp, setShowIPHelp] = useState(false);
  const [showIPv6Help, setShowIPv6Help] = useState(false);
  const [showAtmosphereHelp, setShowAtmosphereHelp] = useState(false);
  const [showHealthCheckHelp, setShowHealthCheckHelp] = useState(false);
  const [showAwayModal, setShowAwayModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const navigate = useNavigate();

  const isFocused = useCallback((id: string) => focused === id, [focused]);
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const handleSubmit = onPick;

  const getImage = (planSpeed: number) => {
    switch (planSpeed) {
      case 50:
        return ringsClayUrl;
      case 100:
        return ringsClayUrl;
      case 1000:
        return diamondClayUrl;
      default:
        return diamondClayUrl;
    }
  };

  const [awaySub, {
    error: awayError,
  }] = useUdpateSubscriptionMutation();

  const [pauseSub, {
    error: pauseError,
  }] = usePauseSubscriptionMutation();

  const [cancelSub, {
    error: cancelError,
  }] = useCancelSubscriptionMutation();

  const disabled = () => sub.status === SubscriptionStatus.Pending || isBeforeNextDayMidnight(sub.updated_at);

  return (
    <Flex direction={['column', 'column', 'column', 'row']} gap={6}>
      <SubscriptionAwayModal
        setShowAwayModal={setShowAwayModal}
        showModal={showAwayModal}
        handleAway={() => {
          awaySub({ plan_id: 'SLC-NBN-10-10', subId: sub.id })
            .unwrap()
            .then(() => showToaster('The away mode has been set successfully', 'success')).catch((err) => showToaster('An error occured setting away mode.', 'error'));
        }}
      />
      <SubscriptionPauseModal
        setShowAwayModal={setShowAwayModal}
        setShowPauseModal={setShowPauseModal}
        showModal={showPauseModal}
        handlePause={() => {
          pauseSub({ subId: sub.id, pause: true })
            .unwrap()
            .then(() => showToaster('The service pause request has been accepted successfully', 'success')).catch((err) => showToaster('An error occured setting pause.', 'error'));
        }}
      />
      <SubscriptionCancelModal
        setShowAwayModal={setShowCancelModal}
        showModal={showCancelModal}
        handleCancel={() => {
          cancelSub({ subId: sub.id, pause: false })
            .unwrap()
            .then(() => showToaster('The service cancellation request has been successfully', 'success')).catch((err) => showToaster('An error occured requesting cancellation.', 'error'));
        }}
      />
      <Box
        sx={{
          _hover: {
            cursor: 'pointer',
          },
        }}
        width="33%%"
        flexBasis={isFocused(serviceId) ? '200%' : '100%'}
        transition="flex-basis 0.2s ease"
        onMouseEnter={() => setFocused(serviceId)}
        onMouseLeave={() => setFocused('')}
        display="flex"
        flexDirection="column"
        key={serviceId}
      >
        {showIPHelp && (
          <Help
            helpHeader="What is IP address ?"
            helpText={IPv4Help}
            isOpen={showIPHelp}
            onClose={() => setShowIPHelp(false)}
          />
        )}
        {showIPv6Help && (
          <Help
            helpHeader="What is IPv6 prefix?"
            helpText={IPv6Help}
            isOpen={showIPv6Help}
            onClose={() => setShowIPv6Help(false)}
          />
        )}
        {showAtmosphereHelp && (
          <Help
            helpHeader="What is Atmosphere?"
            helpText={AtmosphereHelp}
            isOpen={showAtmosphereHelp}
            onClose={() => setShowAtmosphereHelp(false)}
          />
        )}
        {showHealthCheckHelp && (
          <Help
            helpHeader="What are Health Checks?"
            helpText={HealthCheckHelp}
            isOpen={showHealthCheckHelp}
            onClose={() => setShowHealthCheckHelp(false)}
          />
        )}

        <Card
          minHeight="550px"
          flex="1"
          size="lg"
          variant={isFocused(serviceId) ? 'blue' : 'deepSpace'}
        >
          <Box
            display="flex"
            flexDirection="column"
            flex="1"
            bgPosition={
              isFocused(serviceId)
                ? 'bottom -100px right -100px'
                : 'top -150px right -150px'
            }
            bgSize="500px"
            bgRepeat="no-repeat"
            bgImage={getImage(downSpeed)}
          >
            <CardHeader>
              <Heading
                fontSize={isFocused(serviceId) ? '20px' : '16px'}
                fontWeight="bold"
                transition="font-size 0.2s ease-in-out"
                mb="3"
              >
                {title}
              </Heading>

              <SlideFade
                transition={{
                  enter: { duration: 0.3 },
                  exit: { duration: 0.3 },
                }}
                in={isFocused(serviceId)}
              >
                <Box position="relative" display="inline-block">
                  <Text noOfLines={2}>
                    <Menu>
                      {isLargerThan768 ? (
                        <MenuButton
                          marginRight={4}
                          borderRadius={50}
                          bgColor="deepSpace.700"
                          width={100}
                        >
                          Actions
                        </MenuButton>
                      ) : (
                        <MenuButton
                          icon={<HamburgerIcon />}
                          aria-label="Open Menu"
                          display={{ md: 'none' }}
                          marginRight={4}
                          as={IconButton}
                        />
                      )}
                      <MenuList>
                        <Tooltip placement="bottom" label={AtmosphereHelp} closeDelay={0} openDelay={2000}>
                          <MenuItem
                            icon={<FiShield />}
                            command="⌘A"
                            color="black"
                            onClick={() => navigate(`/atmosphere/${encodeURIComponent(sub.id)}`)}
                          >
                            Atmosphere
                          </MenuItem>
                        </Tooltip>

                        <Tooltip placement="bottom" label={HealthCheckHelp} closeDelay={0} openDelay={2000}>
                          <MenuItem
                            color="black"
                            icon={<FiActivity />}
                            command="⌘H"
                            onClick={() => navigate(`/health/${encodeURIComponent(sub.id)}`)}
                          >
                            Health Check
                            {' '}
                          </MenuItem>
                        </Tooltip>
                        <MenuItem
                          color="black"
                          icon={<FiPause />}
                          onClick={() => setShowPauseModal(true)}
                          isDisabled={disabled()}
                          command="⌘P"
                        >
                          Pause
                        </MenuItem>
                        <MenuItem
                          color="pink.500"
                          icon={<FiTrash />}
                          command="⌘D"
                          isDisabled={disabled()}
                          onClick={() => setShowCancelModal(true)}
                        >
                          Disconnect Service
                        </MenuItem>
                      </MenuList>
                    </Menu>

                    {subTitle}
                  </Text>
                </Box>
              </SlideFade>
            </CardHeader>
            <CardBody display="flex" flexDirection="column">
              <Box marginBottom={4}>
                <Heading size="xs">
                  <Icon as={FiGlobe} mr="1" />
                  IPv4 Address:
                  {' '}
                  {sub.ip}
                  <IconButton
                    marginLeft="2"
                    size="xxs"
                    aria-label="ip address help"
                    icon={<FiHelpCircle />}
                    onClick={() => setShowIPHelp(true)}
                  />
                </Heading>
              </Box>

              <Box marginBottom={4}>
                <Heading size="xs">
                  <Icon as={FiGlobe} mr="1" />
                  IPv6 Prefix:
                  {' '}
                  {sub.ip6}
                  <IconButton
                    marginLeft="2"
                    size="xxs"
                    aria-label="ipv6 prefix address help"
                    icon={<FiHelpCircle />}
                    onClick={() => setShowIPv6Help(true)}
                  />
                </Heading>
              </Box>
              <Box marginBottom={4}>
                <Heading size="xs">
                  <Icon as={FiGlobe} mr="1" />
                  IPv6 Delegated Prefix:
                  {' '}
                  {sub.ip6_delegated}
                  <IconButton
                    marginLeft="2"
                    size="xxs"
                    aria-label="ipv6 prefix address help"
                    icon={<FiHelpCircle />}
                    onClick={() => setShowIPv6Help(true)}
                  />
                </Heading>
              </Box>
              <Box>
                <Heading size="small">
                  <Text color={sub.status === SubscriptionStatus.Active ? 'cyan' : 'red.200'}>
                    <Icon as={sub.status === SubscriptionStatus.Active ? FiCheckCircle : WarningTwoIcon} />
                    {' '}
                    Service Status:
                    {' '}
                    {getSubscriptionStatusString(sub.status)}
                    {sub.status === SubscriptionStatus.Pending ? (
                      <Text mt={4} color="grey" maxWidth="380px">The subscription is temporarily blocked from updates as it is currently updating its status.</Text>
                    ) : null}
                    {isBeforeNextDayMidnight(sub.updated_at) ? (
                      <Text color="cyan" maxWidth="380px">
                        The subscription was recently updated. Further updates are restricted until midnight of the next day. You may only change your plan or enable away mode once per day due to NBN&reg; limitations.
                      </Text>
                    ) : null}
                  </Text>
                </Heading>
              </Box>
            </CardBody>
            <CardBody display="flex" alignItems="flex-end">
              <Box>
                <Heading fontWeight="500" as="p" size="lg">
                  $
                  {Math.ceil(monthlyCost * currentDiscount)}
                </Heading>
                <Text color="deepSpace.100" variant="mini">
                  Per month
                </Text>
                <SlideFade
                  transition={{
                    enter: { duration: 0.3 },
                    exit: { duration: 0.3 },
                  }}
                  in={isFocused(serviceId)}
                >
                  <Text
                    color="deepSpace.100"
                    variant="mini"
                    mb="1rem"
                    noOfLines={1}
                  >
                    {currentDiscount !== 1
                      ?? `Including promotional discount of ${currentDiscount}`}
                  </Text>
                </SlideFade>
                <HStack>
                  <Text
                    fontFamily="'Belfast Grotesk', sans-serif"
                    fontSize="2xl"
                    casing="uppercase"
                  >
                    {downSpeed}
                    {' '}
                    <Box as="span" display="inline" mr="1rem" fontSize="xs">
                      MBPS
                    </Box>
                    <Box
                      as="span"
                      display="block"
                      color="deepSpace.100"
                      fontSize="2xs"
                    >
                      Download
                    </Box>
                  </Text>
                  <Text
                    fontFamily="'Belfast Grotesk', sans-serif"
                    fontSize="2xl"
                    casing="uppercase"
                  >
                    {upSpeed}
                    {' '}
                    <Box as="span" display="inline" fontSize="xs">
                      MBPS
                    </Box>
                    <Box
                      as="span"
                      display="block"
                      color="deepSpace.100"
                      fontSize="2xs"
                    >
                      Upload
                    </Box>
                  </Text>
                </HStack>
                <Link
                  to={`${cfg.portalLink}${email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text
                    _hover={{ color: 'pink.200' }}
                    mt={4}
                    fontSize="md"
                  >
                    Invoices | Payment Method
                  </Text>
                </Link>
              </Box>
            </CardBody>
            <CardFooter>
              <Link
                to={`/subscriptions/${encodeURIComponent(sub.id)}/plans`}
              >

                <Popover trigger={disabled() ? 'hover' : undefined} placement="top">
                  <PopoverTrigger>
                    {/* Wrap the button in a Box to enable popover on hover */}
                    <Box>
                      <Button
                        type="button"
                        variant={isFocused(serviceId) ? 'cyan' : 'white'}
                        isDisabled={disabled()}
                      >
                        Change Plan
                      </Button>
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverBody style={{ color: 'black' }}>
                      You can only change plans once per day. Please wait until midnight.
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Link>
              <Popover trigger={disabled() ? 'hover' : undefined} placement="top">
                <PopoverTrigger>
                  {/* Wrap the button in a Box to enable popover on hover */}
                  <Box>
                    <Button
                      marginX="10px"
                      type="button"
                      background="grey"
                      isDisabled={disabled()}
                      _hover={{ bg: 'blue.300', color: 'white' }} // Hover style
                      onClick={async () => {
                        if (sub.status === SubscriptionStatus.Active) {
                          setShowAwayModal(true);
                          return;
                        }
                        if (sub.status === SubscriptionStatus.Away) {
                          navigate(`/subscriptions/${encodeURIComponent(sub.id)}/plans`);
                          return;
                        }
                        if (sub.status === SubscriptionStatus.Paused) {
                          try {
                            const body = await JSON.stringify({
                              email,
                              loc_id: sub.loc_id,
                              subscription_id: sub.id,
                              formatted_address: sub.nbn_details.addressDetail.formattedAddress,
                              stripe_product_id: sub.plan.stripe_product_id,
                            });
                            const checkOutLink = await fetch('/api/v1/resume', {
                              body,
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            });
                            const resp = await checkOutLink.json();
                            window.location.href = resp.payment_link;
                          } catch (err) {
                            console.log('error occured', err);
                            showToaster('error occured while resuming your subscription, try to singup on https://www.neptune.net.au/internet', 'error');
                          }
                        }
                      }}
                      leftIcon={sub.status === SubscriptionStatus.Paused || sub.status === SubscriptionStatus.Away ? <FiPlayCircle /> : <FiPauseCircle />}
                    >
                      {sub.status === SubscriptionStatus.Paused || sub.status === SubscriptionStatus.Away ? 'Resume' : 'Away'}
                    </Button>
                  </Box>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverBody style={{ color: 'black' }}>
                    You can only set Away Mode once per day. Please wait until midnight.
                  </PopoverBody>
                </PopoverContent>
              </Popover>

            </CardFooter>
          </Box>
        </Card>
      </Box>
    </Flex>
  );
};
