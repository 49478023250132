import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Link,
} from '@chakra-ui/react';
import { Plan } from '../models/plans';

export interface SubscriptionChangePlanModalProps {
  setShowChangePlanModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  selectedPlan: Plan;
  handleChangePlan: () => void;
}

export const SubscriptionChangePlanModal: React.FC<SubscriptionChangePlanModalProps> = ({
  handleChangePlan, showModal, setShowChangePlanModal, selectedPlan,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleConfirm = () => {
    if (isChecked) {
      // Handle the confirmation logic here
      setShowChangePlanModal(false);
      handleChangePlan();
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => {
        setShowChangePlanModal(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Change Subscription Plan
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            You are about to change your plan to
            {' '}
            <b>{selectedPlan.name}</b>
            .
          </Text>
          <Text mb={4}>
            This change can only be made
            {' '}
            <b>once per day.</b>
          </Text>
          <Text mb={4}>
            Any remaining charges prepaid for your current plan will either be applied toward the new plan if it costs more, or carried forward to reduce your bill over the following billing periods.
          </Text>
          <Checkbox
            isChecked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          >
            I accept the monthly charge of
            {' '}
            <b>
              $
              {selectedPlan.cost}

            </b>
            {' '}
            (including GST) and
            {' '}
            <Link
              href="https://www.neptune.net.au/terms"
              isExternal
              rel="noopener noreferrer"
              target="_blank"
              color="black"
            >
              The Terms of Service.
            </Link>
          </Checkbox>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor="blue.300"
            color="white"
            onClick={handleConfirm}
            isDisabled={!isChecked}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>

    </Modal>
  );
};
