import React, { useEffect, useState } from 'react';
import 'dayjs/locale/en-au';
import { useNavigate, useParams } from 'react-router';

import dayjs from 'dayjs';
import {
  Box, Button, Spinner, Stack,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  fetchCustomerObject,
} from '../features/customer/customerObjectSlice';
import { DashboardTemplate } from './page_templates';
import { useGetCustomerQuery } from '../services/eventsService';
import { RootState } from '../app/store';
import { PlanPicker } from './ServiceCards';
import { Subscription, SubscriptionStatus } from '../models/customer';
import { isBeforeNextDayMidnight } from '../utils';

dayjs.locale('en-au');

const Plans = () => {
  const dispatch = useAppDispatch();
  const { subId } = useParams();
  const token = useAppSelector((state: RootState) => state.auth.token);
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
    dispatch(fetchCustomerObject(token));
  }, []);

  const {
    data: customer, isLoading: isCustomerLoading,
  } = useGetCustomerQuery('', {
    skip: !token,
  });

  const [subscription, setSubscription] = useState<Subscription>(
    {} as Subscription,
  );

  useEffect(() => {
    if (customer && customer?.subscriptions) {
      const s = customer?.subscriptions[subId || ''];
      if (s) {
        setSubscription(s);
        setBlocked(s.status === SubscriptionStatus.Pending || isBeforeNextDayMidnight(s.updated_at));
      }
    }
  }, [customer, isCustomerLoading]);

  const navigate = useNavigate();

  const [showEvents, setShowEvents] = React.useState(false);

  return (
    <DashboardTemplate>
      {isCustomerLoading || subscription.loc_id === undefined ? <Spinner />
        : (
          <>
            {' '}
            <Box style={{
              alignItems: 'center', textAlign: 'center', paddingTop: '10px', paddingBottom: '10',
            }}
            >
              <p>
                Select a plan from available below and click
                {' '}
                <b>Select.</b>
              </p>
              <p>Your plan will be updated within 5-10 minutes, and any outstanding amount will be prorated to your next invoice, so you won’t be charged anything at this time.</p>
            </Box>
            <Stack direction={['column', 'column', 'column', 'row']} alignItems="ceneter" justifyContent="center">

              <Stack
                py={3}
                px={50}
              >
                {subscription?.loc_id !== undefined && <PlanPicker isBlocked={blocked} setBlocked={setBlocked} buttonText="Select" subId={subId || ''} locationId={subscription.loc_id} />}
              </Stack>
            </Stack>

            <Box style={{
              alignItems: 'center', textAlign: 'center', paddingTop: '10px', paddingBottom: '10',
            }}
            >
              <Button bgColor="gray" isLoading={isCustomerLoading} onClick={() => navigate('/dashboard')}>
                Back to dashboard
              </Button>
            </Box>
          </>
        )}
    </DashboardTemplate>
  );
};

export default Plans;
